/**
 * Copyright 2018, CLOUDPILOTS Software & Consulting GmbH
 *
 * App
 * @css
 *
 */

/*
  Official CLOUDPILOTS colors

  red: #EA4335
  green: #34A853
  yellow: #FBBC05
  blue: #4285F4

*/

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.95);
}
