/**
 * Copyright 2018, CLOUDPILOTS Software & Consulting GmbH
 *
 * index
 * @css
 *
 */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
